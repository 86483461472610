import { cn } from "@/lib/utils";

interface IProps {
  icon: any;
  type?: "ghost" | "primary" | "outline";
  url?: string;
  label: string;
  onClick?: any;
  size?: "sm" | "md" | "lg";
  radius?: "" | "rounded" | "rounded-full";
  disabled?: boolean;
}
export default function ButtonToggleIcon({
  icon,
  label,
  onClick,
  type = "ghost",
  size = "lg",
  radius,
  disabled,
}: IProps) {
  // Color
  let color = "bg-white/10";
  if (type === "primary") {
    color = "bg-lime-600 text-white";
  } else if (type === "outline") {
    color = "border border-lime-600 text-lime-600";
    if (disabled) {
      color = "border border-gray-300 text-gray-300";
    }
  }

  // Size
  let height = "h-10";
  if (size === "md") {
    height = "h-8";
  } else if (size === "sm") {
    height = "h-6";
  }

  return (
    <div
      className={cn(
        "backdrop-sepia-30 group relative flex min-w-20 cursor-pointer items-center justify-center gap-2 overflow-hidden rounded-md px-2 text-sm font-semibold",
        height,
        color,
        radius,
      )}
      onClick={onClick}
    >
      <span className="w-[16px] translate-x-[-80px] transition-transform duration-300 group-hover:translate-x-0">
        {icon}
      </span>
      <span className="translate-x-[-12px] transition-transform duration-300 group-hover:translate-x-0">
        {label}
      </span>
    </div>
  );
}
