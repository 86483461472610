"use client";
import { useTranslation } from "@/app/i18n/client";
import { useOrderStore } from "@stores/rootStoreProvider";
import { Button, Drawer, Form } from "antd";
import { Suspense, useEffect, useState } from "react";
import PageLoadingComponent from "@/app/[domain]/loading";
import { validateMessages } from "@/src/lib/validation";
import BlurImage from "@/src/components/blur-image";
import { observer } from "mobx-react";
import FormSelectButton from "@/src/components/form-items/FormSelectButton";
import FormQuantityInput from "@/src/components/form-items/FormQuantity";
import { formatCurrency } from "@/src/lib/helper";
import FormInput from "@/src/components/form-items/FormInput";
import {
  PRODUCT_ICE_LEVELS,
  PRODUCT_SPICINESS,
  PRODUCT_SWEETNESS,
  PRODUCT_TYPE,
} from "@/src/lib/constants";
import useWindowSize from "@/lib/hooks/use-window-size";

const sizeOptions = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Large", value: "large" },
];

const CartItemDrawer = observer(({}: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const orderStore = useOrderStore();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<any>({});
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const product = orderStore.editOrderDetail?.product ?? {};
    setProduct(product);
    setQuantity(orderStore.editOrderDetail?.quantity ?? 1);
    form.setFieldsValue(orderStore.editOrderDetail);
  }, [orderStore.editOrderDetail]);

  useEffect(() => {
    updatePriceBaseOnVariant(
      product.variants ? product.variants[0] : undefined,
    );
  }, [product]);

  const updatePriceBaseOnVariant = (val: string) => {
    const price = (product.variants || []).find(
      (item: any) => item.name === val,
    )?.price;

    setPrice(price || product.price);
  };

  const updateQuantity = (val: any) => {
    setQuantity(val);
  };

  const onSave = async () => {
    form.validateFields().then(async (values) => {
      setIsLoading(true);
      const newCartItem = {
        ...orderStore.editOrderDetail,
        unitPrice: price,
        ...values,
      };

      orderStore.addCartItem(product, newCartItem);
      setIsLoading(false);
      form.resetFields();
      orderStore.initCartItem();
      orderStore.showCartItemBottomSheet();
    });
  };

  const onCancel = async () => {
    orderStore.showCartItemBottomSheet();
  };

  return (
    <Drawer
      onClose={orderStore.showCartItemBottomSheet}
      open={orderStore.showCartItemDrawer}
      closable={false}
      placement={isMobile ? "bottom" : "right"}
      width="50%"
      title={
        <h2 className="text-xl font-semibold dark:text-white">
          {product.name}
        </h2>
      }
      footer={
        <div className="flex justify-end gap-2">
          <Button disabled={isLoading} loading={isLoading} onClick={onCancel}>
            {t("btn.addAnother")}
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={onSave}
            type="primary"
          >
            {t("btn.addToCart")}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onSave}
        autoComplete="off"
        layout="vertical"
        validateMessages={validateMessages}
        className="custom-form"
      >
        <Suspense fallback={<PageLoadingComponent />}>
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between gap-2">
              <div className="product-image relative flex flex-none justify-center">
                <div className="bg-shadow"></div>
                {product.image && (
                  <div className="relative h-20 w-20">
                    <BlurImage
                      src={product.image ?? ""}
                      alt={product.name ?? ""}
                      className="rounded-lg"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-auto flex-col justify-between gap-2">
                {product.variants && (
                  <div className="size-options text-base">
                    <div className="select-options flex justify-center gap-2">
                      <FormSelectButton
                        options={sizeOptions}
                        name="size"
                        onChange={updatePriceBaseOnVariant}
                      />
                    </div>
                  </div>
                )}
                <div className="quantity-options flex flex-row items-center justify-between text-base">
                  <div className="price flex flex-col font-semibold text-slate-600 md:flex-row md:gap-1">
                    <span>
                      {formatCurrency(price)} {"x"}
                      {quantity}
                    </span>
                    {orderStore.editOrderDetail?.amount && (
                      <span>
                        {t("order.tempAmount")}{" "}
                        {formatCurrency(price * quantity)}
                      </span>
                    )}
                  </div>
                  <div className="quantity-widget">
                    <FormQuantityInput
                      name="quantity"
                      max={product.remaining}
                      onChange={updateQuantity}
                    />
                  </div>
                </div>
              </div>
            </div>
            {product.type === PRODUCT_TYPE.food && (
              <div className="variant-options flex flex-row items-center justify-between gap-4">
                <div className="group-title font-semibold text-slate-600">
                  {t("order.spiciness")}
                </div>
                <div className="select-options">
                  <FormSelectButton
                    name="spiciness"
                    options={PRODUCT_SPICINESS}
                  />
                </div>
              </div>
            )}
            {product.type === PRODUCT_TYPE.drink && (
              <>
                <div className="variant-options flex flex-row items-center justify-between gap-4">
                  <div className="group-title font-semibold text-slate-600">
                    {t("order.iceLevel")}
                  </div>
                  <div className="select-options">
                    <FormSelectButton
                      name="iceLevel"
                      options={PRODUCT_ICE_LEVELS}
                    />
                  </div>
                </div>
                <div className="variant-options flex flex-row items-center justify-between gap-4">
                  <div className="group-title font-semibold text-slate-600">
                    {t("order.spiciness")}
                  </div>
                  <div className="select-options">
                    <FormSelectButton
                      name="sweetness"
                      options={PRODUCT_SWEETNESS}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="extra-options flex flex-row items-center gap-4">
              <div className="group-title flex-0 font-semibold text-slate-600">
                {t("order.note")}
              </div>
              <div className="flex-1">
                <FormInput name="note" formClass="mb-0" />
              </div>
            </div>
          </div>
        </Suspense>
      </Form>
    </Drawer>
  );
});

export default CartItemDrawer;
